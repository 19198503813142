// Reset

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    text-align: left;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

// Rules

@font-face {
  font-family: "JetBrains Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/JetBrainsMono-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "JetBrains Mono";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/JetBrainsMono-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "JetBrains Mono";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/JetBrainsMono-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "JetBrains Mono";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/JetBrainsMono-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/NotoSans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/NotoSans-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/NotoSans-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/NotoSans-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Sans Condensed";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/NotoSans-Condensed.woff2") format("woff2");
}

body {
    font-family: "Noto Sans", sans-serif;
    font-size: 18px;
    line-height: var(--line-height);

    --line-height: 24px;
    // --miku-aoi: #2cadcb;
    --miku-aoi-dark: ##137a7f;
    --miku-aoi: #02BBBA;
    // --miku-aoi-dark: #22535d;
    --miku-red: #ee286e;
    --dark-gray: #373b3e;
    --light-gray: #FAFAFA;
    --light-gray-2: #{darken(#FAFAFA, 10%)};
    --text-color: #000;

    background: var(--miku-aoi);
    color: var(--text-color);

    @media (prefers-color-scheme: dark) {
        & {
            background: #000;
            --dark-gray: #EEE;
            --light-gray: #373b3e;
            --light-gray-2: #{lighten(#373b3e, 10%)};
            --text-color: #FFF;
        }
    }
}

jp, *[lang="ja-JP"] {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 0.95em;
    font-style: normal;
}

zhcn, *[lang="zh-CN"] {
    font-family: "Noto Sans SC", sans-serif;
    font-size: 0.95em;
    font-style: normal;
}

zhtw, *[lang="zh-TW"] {
    font-family: "Noto Sans TC", sans-serif;
    font-size: 0.95em;
    font-style: normal;
}

#site-container {
    display: grid;

    // Page, mobile
    grid-template-columns: [main-start] calc(100vw - 2em) [main-end];
    grid-template-rows: auto;
    grid-template-areas: "header" "main" "footer";
    margin: 0 auto;
    width: calc(100% - 2em);

    @media screen and (min-width: 1024px) {
        & {
            grid-template-columns: [main-start] 66ch [main-end];
            width: 66ch;
        }

        &.home {
            grid-template-columns: [sidebar-start] 22ch [sidebar-end] 2ch [main-start] 66ch [main-end];
            grid-template-rows: auto;
            grid-template-areas: "header . main" ". . footer";
            // transform: translateX(-10ch);
            width: 90ch;
        }
    }
}

#site-header {
    border-top: 0.25rem solid var(--miku-red);
    display: flex;
    font-family: "Noto Sans", sans-serif;
    grid-area: header;
    margin: 0;
    padding: var(--line-height) 0;

    hr {
        display: none;
    }

    @media screen and (min-width: 1024px) {
        .home & {
            border: 0;
            display: block;
            padding-top: calc(0.25rem + 8 * var(--line-height));
            text-align: right;

            h1 {
                color: #FFF;
                font-family: "Noto Sans Condensed", sans-serif;
                font-size: 3rem;
                font-weight: 400;
                line-height: 2.5rem;
                margin: 1.25rem 0 0 0;
                text-align: right;
            }

            hr {
                border-bottom: 0;
                border-left: 0;
                border-right: 0;
                border-top: 0.25rem solid var(--miku-red);
                display: block;
                margin: calc(var(--line-height) / 2) 0;
            }

            nav {
                display: block;
                margin: 0;

                ul {
                    display: block;
                    li {
                        background: var(--miku-red);
                        box-sizing: border-box;
                        color: #FFF;
                        display: block;
                        height: calc(var(--line-height) * 2);
                        margin: var(--line-height) 0;
                        position: relative;
                        transition: 0.1s ease-in all;

                        a {
                            box-sizing: border-box;
                            display: block;
                            height: 100%;
                            padding: calc(var(--line-height) / 2) 1em;
                            text-align: right;
                            text-decoration: none;
                            width: 100%;
                        }

                        &:hover {
                            box-shadow: 0 0em 0.5em var(--miku-red);
                            a {
                                text-shadow: 0 0em 0.5em #FFF;
                            }
                        }
                    }
                }

                a {
                    color: inherit;
                }
            }
        }
    }

    h1 {
        color: #FFF;
        display: inline-block;
        flex: 1 0;
        font-weight: bold;

        a:link, a:visited, a:active {
            color: inherit;
            text-decoration: none;
        }

        a:hover {
            color: inherit;
            text-decoration: underline;
        }
    }

    nav {
        display: inline-block;
        flex: 0 0 auto;
        margin: 0;

        ul {
            li {
                color: #FFF;
                display: inline-block;
            }
        }

        a {
            color: inherit;
            transition: 0.1s ease-in all;
        }

        a:hover {
            text-shadow: 0 0em 0.5em var(--miku-red);
        }
    }
}

main {
    background: var(--light-gray);
    grid-area: main;
    margin: 1rem auto;
    padding: 0 1rem;
    width: calc(100% - 2rem);

    // .home & {
    //     border-top: 0.25rem solid var(--miku-aoi);
    // }

    .page & {
        margin-top: calc(var(--line-height) * 2);

        h2.p-name {
            font-family: "Noto Sans Condensed", sans-serif;
            font-size: 2rem;
            font-weight: normal;
            padding-top: var(--line-height);
        }
    }
}

*[aria-hidden="true"] {
    user-select: none;
}

main {
    border-radius: 0.5em;
    box-shadow: 0 0em 0.2em var(--dark-gray);

    ol, p, ul {
        margin: var(--line-height) 0;
    }

    ol ol, ol ul, ul ul, ul ol {
        margin: 0 0;
    }

    li {
        hyphens: auto;
        text-align: justify;
    }

    hr {
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
        border-top: 0.25rem solid var(--miku-aoi);
        margin: calc(var(--line-height) / 2) 0;
    }

    h1, h2, h3 {
        font-family: "Noto Sans", sans-serif;
        line-height: 1.25em;
        text-wrap: balance;
    }

    h2:not(.p-name) {
        background: var(--dark-gray);
        color: var(--light-gray);
        margin: 0 -1rem;
        padding: 1rem 0 1rem 1rem;
    }

    h2 {
        font-size: 1.25em;
        font-weight: bold;
    }

    h3 {
        font-weight: bold;
        line-height: 1.25em;
        margin: var(--line-height) 0;
    }

    .footnotes {
        border-top: 0.25em dotted var(--dark-gray);
    }

    a:link {
        color: var(--miku-aoi);
    }

    a:visited {
        color: var(--miku-aoi-dark);
    }

    a:hover {
        color: var(--miku-red);
    }

    a:active {
        color: var(--miku-aoi);
    }

    p {
        hyphens: auto;
        text-align: justify;
    }

    details {
        margin: 1em 0;
    }

    ol {
        list-style-type: decimal;
        padding: 0 0 0 1em;

        li {
            // margin-left: 2ch;
            // text-indent: -2ch;

            // p {
            //     display: inline;
            //     margin: 0;
            //     padding: 0;
            // }

            // &::before {
            //     content: "• ";
            // }
        }
    }

    ul {
        list-style-type: disc;
        padding: 0 0 0 1em;

        li {
        }
    }

    blockquote {
        background: var(--light-gray);
        box-sizing: border-box;
        color: var(--dark-gray);
        font-style: italic;
        margin: var(--line-height) 0;
        padding: 0.01em 2em;

        &[lang="ja-JP"] {
            font-style: normal;
        }

        p:first-child {
            margin-top: 0;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    code {
        color: var(--dark-gray);
        font-family: "JetBrains Mono", monospace;
        font-size: 0.9em;
    }

    div.highlight {
        background: var(--light-gray);
        overflow-x: auto;
        padding: var(--line-height) 1ch;

        code {
            color: inherit;

            .c1, .k, .kn, .kt, .p, .si {
                color: var(--dark-gray);
                font-weight: bold;
            }

            .c1 {
                font-style: italic;
            }
        }
    }

    em {
        font-style: italic;
    }

    .callout-warning {
        font-weight: bold;
    }

    p.lyrics, .lyrics p {
        white-space: pre-line;
    }

    .footnote {
        &::before {
            content: "[";
        }
        &::after {
            content: "]";
        }
    }

    strong {
        font-weight: bold;
    }

    table {
        border-collapse: collapse;
        margin: 1em -1rem;
        table-layout: fixed;
        width: calc(100% + 2rem);
    }

    th {
        font-weight: bold;
    }

    td, th {
        padding: 0.5rem;

        p {
        }

        p:first-child {
            margin-top: 0;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    tbody {
        tr:nth-child(odd) {
            background-color: var(--light-gray-2);
        }

        tr:nth-child(even) {
            // background-color: #e495e4;
        }
    }

    time {
        font-style: italic;
        font-weight: normal;
    }
}

#site-footer {
    background: var(--light-gray);
    border-radius: 0.5em;
    box-shadow: 0 0em 0.2em var(--dark-gray);
    grid-area: footer;
    margin: var(--line-height) auto;
    padding: 1rem;
    width: calc(100% - 2rem);

    .header {
        color: var(--dark-gray);
        display: block;
        font-style: italic;
        text-align: right;
        text-wrap: balance;
    }

    hr {
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
        border-top: 0.25rem solid var(--miku-aoi);
        margin: calc(var(--line-height) / 2) 0;
    }
}

.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5em;
    margin: var(--line-height) 0;

    .card {
        // border: 0.1em solid var(--dark-gray);
        // border-bottom: 0.2em solid var(--miku-red);
        border-radius: 0.5rem;
        box-shadow: 0 0 0.05em var(--text-color);
        box-sizing: border-box;
        display: flex;
        flex: 1 0 calc(50% - 0.5em);
        flex-direction: column;
        min-width: 30ch;
        overflow: clip;
        // padding: 1em;

        .title {
            font-family: "Noto Sans", sans-serif;
            font-weight: bold;
            padding: 1rem 1rem 0;

            a {
                color: inherit;
                text-decoration: none;
            }
        }

        .body {
            flex: 1 0;
            font-size: 0.9em;
            line-height: 1.25em;
            padding: 0 1rem;

            p {
                text-align: left;
            }
        }

        .footer {
            background: var(--miku-aoi);
            color: #FFF;
            display: flex;
            font-size: 0.9em;
            padding: 1rem;

            > div {
                flex: 1 0 50%;
            }

            time {
                display: inline-block;
                text-align: right;
                width: 100%;
            }

            a {
                color: inherit;
            }
        }
    }

    .card-double {
        flex: 1 0 100%;
    }
}
